function waveAnimation() {
  var targets = document.querySelectorAll('.js-wave');
  for (var index = 0; index < targets.length; index++) {
    lottie.loadAnimation({
      container: targets[index],
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/inc/lottie/common/wave.json',
      rendererSettings: {
        className: 'waveMov'
    }
    });
  }
}

waveAnimation();